import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [{
  id: 'dashboard-recruiter-v2',
  title: 'Recruiter Dashboard',
  type: 'item',
  icon: 'dashboard',
  url: '/dashboard/recruiter-v2',
}, {
  id: 'recruiter',
  title: 'Recruiter',
  type: 'group',
  children: [{
    id: 'candidates',
    title: 'Candidates',
    type: 'item',
    icon: 'assignment_ind',
    url: '/candidates',
  }, {
    id: 'vacancies',
    title: 'Vacancies',
    type: 'item',
    icon: 'assignment',
    url: '/vacancies',
  }, {
    id: 'reruns',
    title: 'Reruns',
    type: 'item',
    icon: 'cached',
    url: '/reruns',
  }]
}, {
  id: 'administrator',
  title: 'Administrator',
  type: 'group',
  children: [{
    id: 'job-profiles',
    title: 'Job Profiles',
    type: 'item',
    icon: 'business_center',
    url: '/job-profiles',
  }, {
    id: 'clients',
    title: 'Clients',
    type: 'item',
    icon: 'domain',
    url: '/clients',
  }, {
    id: 'users',
    title: 'Users',
    type: 'item',
    icon: 'people_alt',
    url: '/users',
  }, {
    id: 'notifications',
    title: 'Notifications',
    type: 'item',
    icon: 'notifications',
    url: '/notifications',
  },
  {
    id: 'umalusi',
    title: 'Umalusi',
    type: 'item',
    icon: 'rule_folder',
    url: '/umalusi',
  },
  {
    id: 'farosian',
    title: 'Farosian',
    type: 'item',
    icon: 'rule_folder',
    url: '/farosian',
  },
  {
    //   id: 'operators',
    //   title: 'Operators',
    //   type: 'item',
    //   icon: 'people_alt',
    //   url: '/operators',
    // }, {
    id: 'settings',
    title: 'Settings',
    type: 'item',
    icon: 'settings',
    url: '/settings',
  }
  ]
}
];
