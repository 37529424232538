<div class="navbar-header h-56" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

  <div class="logo">
    <img class="logo-icon" src="assets/images/logos/clickncheck.svg">
    <!-- <span class="logo-text">FUSE</span> -->
  </div>

  <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
    <mat-icon>menu</mat-icon>
  </button>

  <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
    <mat-icon>arrow_back</mat-icon>
  </button>

</div>

<div class="navbar-content" style="background-color: white !important;" fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{suppressScrollX: true}" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
  <fuse-navigation layout="vertical"></fuse-navigation>
</div>

<div class="py-8 text-center" fxLayout="column">
  <!-- <img src="assets/images/logos/clicknhire.svg"> -->

  <div fxLayout="row" fxLayoutAlign="center center" class="p-4 cursor-pointer">
    <mat-icon style="font-size:20px;width:20px;height:20px;min-width: unset;min-height: unset; margin-right:4px">
      book_open_variant
    </mat-icon>
    <a *ngIf="user$ | async as user" (click)="onOpenSystemUserGuide()"
      [style.color]="(user.acceptedSystemUserGuide?'black':'#0091D0')">
      System User Guide
    </a>
  </div>

  <a (click)="onOpenUserDisclaimer()" style="color:rgb(173, 173, 173);cursor: pointer;">
    User disclaimer
  </a>
</div>