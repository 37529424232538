import { toStartcase, reduceObject } from "../utils";

export class Address {
  lineOne: string | null;
  lineTwo: string | null;
  city: string | null;
  province: string | null;
  code: string | null;

  constructor(address: any) {
    this.lineOne = toStartcase(address.lineOne);
    this.lineTwo = toStartcase(address.lineTwo);
    this.city = toStartcase(address.city);
    this.province = toStartcase(address.province);
    this.code = address.code || null;
  }

  public getRawValue(nullExceptions?: string[]): object | null {
    return reduceObject(this, nullExceptions);
  }
}