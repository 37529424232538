import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemUserGuideDialogComponent } from './system-user-guide-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    PdfViewerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatCheckboxModule
  ],
  exports: [SystemUserGuideDialogComponent],
  declarations: [SystemUserGuideDialogComponent],
  entryComponents: [SystemUserGuideDialogComponent]
})
export class SystemUserGuideDialogModule { }
