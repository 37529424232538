import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toLowercase } from '@clickncheck/shared/src';
import { ICompany } from '@core/models/company.model';
import { IUser } from '@core/models/user.model';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ResetUser, SetUser } from '@store/user/user-store.actions';
import { environment } from 'environments/environment';
import firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

interface Credentials {
  email: string;
  password: string;
}

@Injectable({
  providedIn: "root",
})
export class IamService {
  tempFb: firebase.app.App = null;
  attempt = 0;

  constructor(
    private afa: AngularFireAuth,
    private afs: AngularFirestore,
    private store: Store,
    private snackbar: MatSnackBar,
    private http: HttpClient
  ) {
    this.afa.authState.pipe(
      switchMap((user) => user ? this.afs.doc<IUser>(`users/${user.uid}`).valueChanges() : of(null))
    ).subscribe(async (userDocument: IUser) => {
      if (userDocument) {
        const company = await this.afs.doc<ICompany>(`companies/${userDocument.company.uid}`).ref.get();
        this.store.dispatch(new SetUser({ ...userDocument, companyUnderReview: (company.data().underReview || false) } as any));
      } else {
        this.store.dispatch([
          new ResetUser(),
          // new Navigate(['/sign-in'])
        ]);
      }
    });
  }

  public get getAuthState(): Observable<firebase.User> {
    return this.afa.authState.pipe(first());
  }

  public async login(credentials?: Credentials): Promise<void | any> {
    try {
      const { email, password } = credentials;
      const login = this.logInWithEmail({ email, password });

      return login;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  private async logInWithEmail(credentials: {
    email: string;
    password: string;
  }): Promise<{
    companyActiveState: boolean;
    userActiveState: boolean;
    acceptedDisclaimer: boolean;
    name: string;
    passwordSetDate: { seconds: number } | null;
  }> {
    const { email, password } = credentials;
    try {
      await this.afa.setPersistence(
        firebase.auth.Auth.Persistence.SESSION
      );
      const credential = await this.afa.signInWithEmailAndPassword(
        email,
        password
      );

      const userDoc = await this.afs
        .doc<IUser>(`users/${credential.user.uid}`)
        .ref.get();

      if (!userDoc.exists) {
        console.error("User document does not exist");
        return Promise.reject();
      }

      const companyDoc = await this.afs
        .doc<ICompany>(`companies/${userDoc.data().company.uid}`)
        .ref.get();

      if (!companyDoc.exists) {
        console.error("Client document does not exist");
        return Promise.reject();
      }

      const {
        active: userActiveState,
        acceptedDisclaimer,
        name,
        passwordSetDate = null,
      } = userDoc.data();
      const { active: companyActiveState } = companyDoc.data();

      // sendOTP
      try {
        const mUrl = `https://europe-west1-${environment.firebaseConfig.projectId}.cloudfunctions.net/setOTP`;
          const { success } = await this.http.post(mUrl, {
              uid: credential.user.uid
          }).toPromise() as any;
    } catch (e) {}

      return Promise.resolve({
        userActiveState,
        acceptedDisclaimer,
        name,
        passwordSetDate,
        companyActiveState
      });
    } catch (e) {
      if (['auth/too-many-requests', 'auth/wrong-password'].includes(e.code)) {
        this.attempt += 1;
        if (this.attempt >= 3) {
          const account = await this.afs.collection('users').ref.where('emailAddress', '==', credentials.email).get();
          if (account.size >= 1) {
            // await account.docs[0].ref.update({ active: false });
            this.http.post('https://europe-west1-dev-clickncheck.cloudfunctions.net/deactivateUserAccount', { uid: account.docs[0].ref.id }, { headers: { 'Content-Type': 'application/json' } });
            this.snackbar.open('Too many attempts. Your account has been deactivated. Please contact your system administrator to unlock');
          }
          return Promise.reject();
        }
        return Promise.reject(e);
      } else {
        return Promise.reject(e);
      }
    }
  }

  public async signOut(disableSnackbar?: boolean): Promise<void> {
    await this.afa.signOut();
    if (!disableSnackbar) {
      this.snackbar.open("Successfully signed out");
    }
    this.store.dispatch(new Navigate(["sign-in"]));
  }

  public async updatePassword(password: string): Promise<void> {
    return (await this.afa.currentUser).updatePassword(password);
  }

  public async resetPassword(email: string): Promise<void> {
    return this.afa.sendPasswordResetEmail(email);
  }

  public createNewUser(userEmail: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        this.tempFb = firebase.initializeApp(
          environment.firebaseConfig,
          "tempFb"
        );

        const createUserPromise = await this.tempFb
          .auth()
          .createUserWithEmailAndPassword(
            userEmail,
            "g|9?[k(x8e*g%Fa9.tM}q8#Z.yt6>7p15#7+_W+qL!O5X#P#"
          );

        this.tempFb.auth().signOut();
        this.tempFb.delete();

        resolve(createUserPromise.user.uid);
      } catch (e) {
        this.tempFb.delete();
        console.error(e);
        reject(e);
      }
    });
  }

  public updateEmail(newEmail: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await (await this.afa.currentUser).updateEmail(newEmail);

        await this.afs
          .doc(`users/${(await this.afa.currentUser).uid}`)
          .update({ email: toLowercase(newEmail) });

        resolve(true);
      } catch (e) {
        console.error(e);

        reject(false);
      }
    });
  }

  private errorHandler(e: any): void { }
}
