<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>System User Guide</span>
    <span style="flex: 1 1 auto;"></span>
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div fxLayout="column" fxLayoutAlign="start start" class="p-16 sug-container" [style.fontSize]="'16px'">
  <p>The system user guide can be downloaded
    <a class="cursor-pointer" [href]="src" target="_blank">
      here
      <mat-icon style="font-size:12px;width:12px;height:12px;min-width: unset;min-height: unset; margin-left:2px">
        open_in_new
      </mat-icon>
    </a>.
  </p>

  <p>
    Should you require any further assistance, you can book system training to see the full
    benefits of what the system has to offer by sending an email to <a href="mailto:admin@clicknhire.co.za"
      target="_blank">admin@clickmhire.co.za</a>
  </p>

  <div class="w-100-p mt-12" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="user$ | async as user">
    <mat-checkbox *ngIf="!user.acceptedSystemUserGuide" (change)="updateAcceptance()" [color]="'primary'">
      Accept System User Guide
    </mat-checkbox>
    <span *ngIf="showAcceptanceMessage" class="grey-fg">Thank you for accepting</span>
    <button mat-raised-button color="primary" (click)="dialogRef.close()">Close</button>
  </div>
</div>