import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Select } from '@ngxs/store';
import { IUser } from '@core/models/user.model';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-system-user-guide-dialog',
  templateUrl: './system-user-guide-dialog.component.html',
  styleUrls: ['./system-user-guide-dialog.component.scss']
})
export class SystemUserGuideDialogComponent {
  src = 'https://firebasestorage.googleapis.com/v0/b/prod-clickncheck.appspot.com/o/System%20User%20Guide.pdf?alt=media&token=dbfdc95c-0c59-4d07-90b7-8c1eeb3d7610';
  showAcceptanceMessage = false;
  @Select((s) => s.user) user$: Observable<IUser>;

  constructor(
    public dialogRef: MatDialogRef<SystemUserGuideDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: { message: string, expectingResult: boolean }
    private _afs: AngularFirestore
  ) { }

  async updateAcceptance(): Promise<void> {
    this.showAcceptanceMessage = true;
    const { uid } = await this.user$.pipe(take(1)).toPromise();
    await this._afs.doc(`users/${uid}`).update({ acceptedSystemUserGuide: true });
  }

}
