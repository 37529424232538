import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TermsAndConditionsModule } from '@pages/auth/disclaimer/children/terms-and-conditions/terms-and-conditions.module';
import { NavbarVerticalStyle2Component } from 'app/layout/components/navbar/vertical/style-2/style-2.component';

@NgModule({
  declarations: [
    NavbarVerticalStyle2Component
  ],
  imports: [
    MatButtonModule,
    MatIconModule,

    TermsAndConditionsModule,

    FuseSharedModule,
    FuseNavigationModule,

    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    NavbarVerticalStyle2Component
  ]
})
export class NavbarVerticalStyle2Module {
}
