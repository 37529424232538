import { reduceObject, toLowercase, toStartcase } from '../utils';

export class User {
  displayName: string | null;
  uid: string;
  email: string | null;
  active: boolean;

  constructor(user: any) {
    this.displayName = toStartcase(user.displayName);
    this.uid = user.uid;
    this.email = toLowercase(user.email);
    this.active = user.active || true;
  }

  public getRawValue(nullExceptions?: string[]): object | null {
    return reduceObject(this, nullExceptions);
  }

  // public getRawProfileValue(): object | null {
  //   const { firstName, lastName, displayName, idNumber, phone } = this;
  //   return reduceObject({ firstName, lastName, displayName, idNumber, phone }, ['firstName', 'lastName', 'idNumber', 'phone']);
  // }
}