<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>Data Use Agreement & Indemnity</span>
    <span style="flex: 1 1 auto;"></span>
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div style="max-height: 85vh;" perfect class="p-12" fusePerfectScrollbar>
  <b> Data Use Agreement (“Data Agreement”)</b>
  <p>This Data Agreement forms part of an agreement for the provision of Services ("Subscriber Agreement") undertaken
    between us and you.
    Where applicable, your continued use of the information shall constitute deemed acceptance of the terms of this Data
    Agreement. </p>

  <ol>
    <li><b>Definitions</b>
      <p>In this Data Agreement the following words or phrases shall have the meanings as set out below:</p>
      <ol>
        <li><b>“Act”</b> means the National Credit Act, 34 of 2005 and any regulations made thereto (as amended from
          time to time);
        </li>
        <li><b>“Consumer Credit Information”</b> shall bear the meaning as set out in section 70(1)(a) – (d) of the Act,
          Regulation 18(6), and shall include the
          consumer information supplied as part of the Services as well as the meaning that has been assigned to it in
          terms hereof;</li>
        <li><b>“Credit Regulatory Framework”</b> means all legislation and law applicable to credit bureaux, credit
          providers, consumer credit information,
          consumer and personal information and contactability information including the Act, Regulations and
          requirements of the Credit Bureau
          Association, Credit Ombud, the South African Credit and Risk Reporting Association as well as the Protection
          of Personal Information Act and
          any other legislation that may be enacted in future;</li>
        <li><b>“Intellectual Property”</b> includes without limitation, all trademarks, service marks (whether
          registered or unregistered), copyright (including all
          copyright in and to the website and the database and all computer programmes associated therewith), patents,
          know-how, confidential
          information including methods, manuals, specifications, technical information and data relating to the
          Services application and to the Payment
          Profile Credit Bureau and other proprietary rights whether existing now or in the future throughout the world
          and specifically includes the scoring
          variables, scoring processes, and the look and feel, design and lay-out of the Reports;</li>
        <li><b>“Payment Profile Credit Bureau”</b> means the registered credit bureaux who are authorised by the NCR to
          retain Payment Profile Information
          and other Consumer Credit Information on its database, from whom we obtain our Consumer Credit Information for
          the purpose of on-selling it
          to you. This includes, but is not limited to Compuscan, TransUnion, Experian, XDS, CPB and VeriCredit;</li>
        <li><b>“Payment Profile Information”</b> means the payment history and financial information relating to a debt
          or credit transaction, including relevant
          payment dates, both negative and positive information and/or signs depicting action taken in respect of such
          debt or credit transaction;</li>
        <li><b>“Prescribed Purpose”</b> means the specified purpose for which you are entitled to use the Consumer
          Credit Information as allowed for in
          Regulation 18(4), 23A and the Credit Regulatory Framework as well as the purpose for which you may use the
          Consumer Credit Information
          requested;</li>
        <li><b>“RCB”</b> means the Reseller Credit Bureau, which is ClicknHire], a registered reseller credit bureau
          with the NCR with NCR registration number
          [TBC], also referred to “us”, “our” or “we” in this agreement.</li>
        <li><b>“Reports”</b> is the credit report provided you in terms of the Services;</li>
        <li><b>“Services”</b> means the on-line service in terms of which Consumer Credit Information that has been
          collected from the Payment Profile Credit
          Bureaux is made available to you through your website, interfaces, applications or similar platforms relating
          to the credit worthiness of a
          consumer;</li>
        <li><b>“Subscriber”</b> means you or the entity that you work for which entered into a Subscriber Agreement with
          the RCB in which the RCB renders
          services to the Subscriber as described herein.</li>
      </ol>
    </li>

    <li><b>Your access to and use of the Consumer Credit Information</b>
      <ol>
        <li>
          <b>Data access and use.</b>
          <ol>
            <li>You will access and use the Consumer Credit Information only as permitted in terms of the Credit
              Regulatory Framework and with the
              consumer’s consent where required;
            </li>
            <li>You will comply with all legislation applicable to your business, specifically the Credit Regulatory
              Framework;</li>
            <li>You will use Consumer Credit Information for a Prescribed Purpose or such other purpose that may be
              permitted in terms of the Credit
              Regulatory Framework;</li>
            <li>Prior to requesting and accessing Consumer Credit Information, including accessing the information for
              the purpose of risk assessment
              and sharing payment profiles with credit providers, you will comply with the requirements of the Credit
              Regulatory Framework, specifically
              obtaining Consent from the Consumer as and when required;
            </li>
            <li>You will ensure that you obtain the necessary Consent from the Consumer when required;</li>
            <li>You will adhere to all the relevant sections of the Act, together with any Regulations issued in terms
              thereof, where you are provided with
              any data in terms of this Agreement for the purpose of providing Debt Counselling services;
            </li>
            <li>You will comply and implement the regulatory requirements in terms of all relevant legislation
              (including the Credit Regulatory Framework),
              as and when amended and enacted;
            </li>
            <li>You further agree to co-operate with us and the Payment Profile Credit Bureaux to assist us and them to
              meet the requirements of the
              Credit Regulatory;</li>
            <li>You explicitly confirm that you will not access any Consumer Credit Information for the purpose of
              marketing to consumers;</li>
            <li>If you are a consumer and the report is provided directly to you, you hereby consent that we may obtain
              the report on your behalf and
              deliver it directly to you in the manner and form authorised by you, which may include to email, SMS or
              WhatsApp the report directly to
              you or to provide you with access to our online platform where you may obtain your report.</li>
          </ol>
        </li>
        <li><b>No re-selling.</b> You will not on-sell any Consumer Credit Information to any third party.
        </li>
        <li><b>Access to Payment Profile Information. </b>Where you or the Subscriber is a person as set out in
          Regulation 18(7)(b) to (g) of the Act and require
          access to a Report containing Payment Profile Information, you hereby confirm that you comply with the
          Guideline issued to credit bureaux and
          credit providers in accordance with Regulation 19(13), in that you provide the Payment Profile Information in
          your possession to the Payment
          Profile Credit Bureaux through the South African Credit and Risk Reporting Association’s Data Transmission
          Hub.
        </li>
        <li><b>Data disputes.</b> We are a registered RCB and a data subscriber of a Payment Profile Credit Bureau
          Therefore, if any of the information contained
          in the credit report is incorrect, outdated or unfair, you may log a dispute with the Payment Profile Credit
          Bureau via their website or call centre,
          which information is provided on the credit report.
          <ol>
            <li>When you contact their call centre, you will be supplied with a reference number. You will be requested
              to
              submit certain documentation
              to verify your identity and in support of your dispute.
            </li>
            <li>Allow 20 business days for the Payment Profile Credit Bureau to resolve your dispute or query. During
              this
              time, they will contact the
              supplier of the disputed data for further information and evidence relating to the data. The information
              being
              disputed will be removed from
              display during the 20-business day investigation period. If, at the end of this period, the Payment
              Profile
              Credit Bureau does not receive
              credible evidence from the supplier to support the data, the dispute will be resolved in your favour.</li>
            <li>If you are dissatisfied with the outcome, please contact the Credit Ombud on either 086 166 2837, <a
                href="ombud@creditombud.org.za">ombud@creditombud.org.za</a> or at <a
                href="www.creditombud.org.za">www.creditombud.org.za</a>
              for further assistance, bearing in mind that their services are free to consumers.
              Remember to provide your
              dispute reference number in order for the Credit Ombud to assist you.</li>
            <li>You may only also contact the National Credit Regulator on 087 234 7822 or <a
                href="mailto:complaints@ncr.org.za">complaints@ncr.org.za</a> should you have any further
              complaints.</li>
          </ol>
        </li>
        <li><b>Completeness of data.</b> We obtain the Consumer Credit Information from a Payment Profile Credit Bureau
          registered with the NCR and cannot
          and do not guarantee the accuracy or completeness of the Services and Consumer Credit Information. You
          accordingly
          acknowledge that you
          are responsible for the risk with respect to the quality and performance and reliance that you place on the
          Consumer Credit Information and
          you shall further bear all risk in connection with your use of the Services.
        </li>
        <li><b>Errors.</b> The service may contain technical and typographic errors and you acknowledge that neither us,
          our
          third-party suppliers or agents, nor
          the Payment Profile Credit Bureau shall be liable for any liability for errors or omissions with respect to
          the
          Services, regardless of the cause or
          the source of such error or omission. </li>
        <li><b>Changes to content.</b> You acknowledge and agree that as the Services are continually being amended and
          updated by us, we may from time
          to time, and in our sole discretion, change the content or the format of the Services and the location of
          delivery, in order to improve its operation
          and efficiency. </li>
        <li><b>Security.</b> We and the Payment Profile Credit Bureaux will utilise our best efforts to maintain the
          security of the database. You acknowledge
          and agree that no damages can be attributed to us or the Payment Profile Credit Bureaux by virtue of a breach
          of
          security on applications
          provided by us or them. You further agree to comply with and shall ensure that all your employees,
          representatives, sub-contractors and agents
          abide by our policies, processes and standards in force from time to time, provided that such policies have
          been
          made available to you. The
          parties shall immediately bring to the attention of the other party any breach or attempted breach of security
          of
          which it becomes aware.
        </li>
        <li><b>Services.</b> It is your responsibility, at your own cost to maintain the data and telecommunications
          lines
          for purposes of receiving and accessing
          the Consumer Credit Information.</li>
        <li><b>Data retention.</b> You acknowledge and take notice that we are only allowed to retain the Consumer
          Credit
          Information on our servers and
          databases for a maximum of 72 hours, after which it will be deleted. Where available, we will be able to
          retain a
          reference number of the Report
          which can be requested again from the Payment Profile Credit Bureaux, which may incur additional costs. We
          therefore recommend, which
          recommendation you accept, that you download and retain the report on your own computer or similar device
          should
          you require to access the
          report again.</li>
        <li><b>Data transfers.</b> You may only transfer Consumer Credit Information to persons who are authorised to
          receive, view and/or access the Consumer
          Credit Information. You are specifically prohibited from transferring the Consumer Credit Information to any
          unauthorised person. Should you
          abuse your access to the Consumer Credit Information we may immediately revoke your access to the Consumer
          Credit
          Information.</li>
        <li><b>Compliance assessment.</b>
          <ol>
            <li>We or our authorised representative, reserves the right to perform regular compliance assessments,
              pertaining to all Consumer Credit
              Information provided by us to you, on our systems, procedures and contracts in so far as compliance with
              the
              terms of this Agreement is
              concerned. </li>
            <li>We shall be entitled to conduct a compliance assessment, either off-site or at your premises to
              determine
              whether you conform to the
              restrictions governing the use of the Consumer Credit Information, whether you use the Services for a
              lawful
              purpose, whether you have
              the necessary Consent and whether you comply with its duties in terms of this Data Agreement and the
              Credit
              Regulatory Framework. </li>
            <li>You undertake to, within 1 (one) month of acceptance of this Data Agreement to implement an effective
              audit
              trail for each and every
              transaction in respect of the Services and will make such audit trail available to us within 14 (fourteen)
              days upon request thereof from us.</li>
          </ol>
        </li>
        <li><b>Employment certifications.</b> Where you access the Consumer Credit Information for the purpose of
          considering a candidate for employment
          in a position that requires honesty in dealing with cash or finances, you hereby warrant, agree and certify to
          us
          that:
          <ol>
            <li>You are in the service of the Subscriber and the facts contained herein, are within your direct and
              personal
              knowledge.</li>
            <li>You and the Subscriber complies with the provisions and regulations of the NCA applicable to its
              activities
              and have been complying with
              it since it was amended and/or inserted into the NCA.</li>
            <li>You and the Subscriber certifies that it will access the information from us as stipulated in this Data
              Agreement.</li>
            <li>Specifically, where it relates to the prescribed purpose of considering a candidate for employment in a
              position that requires honesty in
              dealing with cash or finances:
              <ol>
                <li>You and the Subscriber hereby certifies that it obtains the required consumer consent (which may be
                  electronically), before the credit
                  records are requested from us (Regulation 18(5)) and that you will provide us with such consent prior
                  to
                  accessing the consumer’s
                  credit profile; and</li>
                <li>For any and all requests for consumer credit records for the prescribed purpose as set out in
                  Regulation
                  18(4)(c) from us, you and
                  the Subscriber hereby further certifies that the position relates to a position requiring honesty in
                  dealing with (i) Cash or (ii) Finances
                  and that the job descriptions of such positions are clearly outlined by the Subscriber (Regulation
                  19(12))
                  and that you will provide us
                  with such job description prior to accessing the consumer’s credit profile.</li>
              </ol>
            </li>
            <li>You further agree that we may make inquiries to confirm any information provided by you and that we may
              verify the information and obtain
              additional information from any registered credit bureau, which may include us to request you or the
              Subscriber to provide us with the
              consumer consent, this certification and the job description of the position the consumer applied for
              within a
              reasonable time.
            </li>
            <li>You lastly warrant that all information supplied to us is to the best of your knowledge true and
              correct,
              that you are not aware of any other
              information that would affect the credit application of the consumer in any way and that you are
              authorised to
              accept this terms and
              conditions.</li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <b>Indemnity</b>
      <ol>
        <li>You acknowledge that you have knowledge of the relevant statutory requirements applicable to the Consumer
          Credit Information and the Services
          and that you agree to comply therewith and to perform your duties and responsibilities as set out in this Data
          Agreement:
          <ol>
            <li>You agree to protect, defend, indemnify and hold harmless us and all our subsidiaries, holding company,
              officers, employees, agents and
              representatives, including those of the Payment Profile Credit Bureaux against all actual or contingent
              liabilities, damage, losses, demands,
              legal proceedings, costs, fees, compensations, expenses and claims whatsoever arising out of, or resulting
              from, or in connection with
              claims by regulators, governmental authorities, or others for non-compliance by you of any legislations,
              regulation or code, provided that
              compliance was required in terms thereof.</li>
            <li>Without limiting the afore going, you further indemnify and hold us and the Payment Profile Credit
              Bureau harmless from and against any
              damage, losses and liabilities howsoever suffered as a result of:
              <ol>
                <li>You breaching any obligations or warranties made by us or the Payment Profile Credit Bureau;</li>
                <li>Any breach of the terms of this Data Agreement by you and/or your employees, and specifically any
                  contravention of the clauses
                  pertaining to your access and use of the Consumer Credit Information; and </li>
                <li>Release of the Consumer Credit Information to a third party.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>You hereby indemnify us and/or the Payment Profile Credit Bureaux against any and all liability arising
          from:
          <ol>
            <li>The use and disclosure of Information procured from us and/or the Payment Profile Credit Bureaux;</li>
            <li>Non-compliance of all and any applicable legislation or other requirements imposed by any statutory
              body, including the rules of the Credit
              Regulatory Framework;
            </li>
            <li>The misuse or negligent use of the Consumer Credit Information procured from us and/or the Payment
              Profile Credit Bureaux outside of
              the Prescribed Purpose.
            </li>
          </ol>
        </li>
        <li>We indemnify and hold you harmless against liability to a third party arising from the gross negligence of
          us and/or which relates to a violation by us
          of any third party’s intellectual property arising as a result of the provision of the Services.</li>
      </ol>
    </li>
    <li><b>Intellectual Property Rights</b>
      <ol>
        <li>The Parties agree that all Intellectual Property belonging to a party prior to entering into this Data
          Agreement shall remain vested in that Party.</li>
        <li>You expressly acknowledge that:
          <ol>
            <li>The Intellectual Property rights belong to us or the Payment Profile Credit Bureaux and/or its licensors
              and agents and include any
              applications or renewals of such rights and are protected by intellectual property laws;</li>
            <li>The compilation of such Intellectual Property is also the exclusive property of us or the Payment
              Profile Credit Bureaux, as the case may
              be, and is likewise protected by South African and international copyright laws. The name of the Payment
              Profile Credit Bureau and the
              Payment Profile Credit Bureau’s logo are registered trademarks of the Payment Profile Credit Bureau and
              its affiliated companies and may
              not be used in any manner without the Payment Profile Credit Bureau’s permission; and</li>
            <li>The Intellectual Property rights remain the exclusive property of the Payment Profile Credit Bureau and
              nothing in this Agreement intends
              to transfer any intellectual property rights to you.
            </li>
          </ol>
        </li>
        <li>You accordingly agree to:
          <ol>
            <li>Take reasonable precautions to avoid unauthorised access to the deliverables or distribution of the
              Consumer Credit Information;</li>
            <li>Protect the copyright and/or any other proprietary rights of us and the Payment Profile Credit Bureaux
              including, but not limited to, any
              contractual and common law rights during and after the term of this Data Agreement; </li>
            <li>Comply with all reasonable requests made by us or the Payment Profile Credit Bureaux to protect its
              rights and Intellectual Property; and</li>
            <li>Not remove, alter, cover or obscure any confidentiality, trade secret, proprietary or other copyright
              notices, trademarks or other proprietary
              or identifying marks or designations from any component of deliverables, nor permit others to take any of
              the aforesaid actions.</li>
          </ol>
        </li>
      </ol>
    </li>
    <li><b>Limitation of Liability</b>
      <ol>
        <li>We, our shareholders, consultants and employees will not be liable for any damages sustained by you,
          including indirect, incidental, special,
          consequential or punitive damages. Without affecting the aforegoing, we shall not be liable, and hereby
          disclaim all liability for:
          <ol>
            <li>Any interruption, malfunction, downtime or other failure of the Services for whatever reason;</li>
            <li>Any interruption, malfunction, downtime or other failure of the goods or services provided by third
              parties, including, without limitation,
              Telkom, Internet service providers, Eskom and other electricity suppliers or local authorities; or </li>
            <li>An event of force majeure or any other event over which we have no direct control.</li>
          </ol>
        </li>
        <li>We shall not be liable, and hereby disclaim all liability for the accuracy of the Consumer Credit
          Information</li>
        <li>Nothing in this Data Agreement shall be construed as us providing you or any third party with a guarantee
          against loss, liability, injury or damages.
          To the maximum extent permitted by applicable law we disclaim all other guarantees and conditions not
          expressly given.</li>
        <li>Notwithstanding the provisions of this clause, in the event that we are found to be liable to you for any
          amount, our liability shall not exceed those
          amounts paid by you to us in terms of the Subscriber Agreement for the specific services during a period of 3
          (three) months preceding the instance
          from which such liability arose.</li>
        <li>It is further expressly recorded that we shall not be liable for any prosecution of you in the event that it
          makes use of the Consumer Credit Information
          in contravention of any applicable law.
        </li>
      </ol>
    </li>
    <li><b>Compliance</b>
      <ol>
        <li>You shall, in connection with the use of the Consumer Credit Information, comply with all legislation,
          regulations, and other rules having equivalent
          force and which are applicable to it, including the Protection of Personal Information Act. To the extent that
          the information comprises of personal
          and/or confidential information specifically protected by any law, you warrant that you will not use or
          process that information in any manner that
          would have the effect of breaching the legal rights of the person to whom it relates. You agree to assist us
          and the Payment Profile Credit Bureaux
          with any investigations regarding adherence to the requirements of this Data Agreement and will supply all
          reasonable requested information within
          2 business days or such time agreed to between the parties.</li>
      </ol>
    </li>
    <li><b>Termination</b>
      <ol>
        <li>This Data Agreement shall terminate on expiry of the Subscriber Agreement. Termination of this Data
          Agreement (or of any element of it) shall not
          affect any rights, obligations or liabilities of either party which have accrued before termination or which
          are intended to continue to have effect
          beyond termination.</li>
      </ol>
    </li>
    <li><b>General</b>
      <ol>
        <li>You may not cede, assign, transfer, charge, or deal in any other manner with this Data Agreement or any
          rights pertaining to or afforded in terms of
          this Data Agreement</li>
        <li>If any other part of the Subscriber Agreement is found to be invalid or unenforceable by any court, such
          invalidity or unenforceability shall not affect
          this Data Agreement and to the furthest extent possible, this Data Agreement shall remain in full force and
          effect. The provisions of this Data
          Agreement are severable from each other and should any part be held invalid or unenforceable, the remaining
          provisions shall continue to have
          effect</li>
        <li>If we or the Payment Profile Credit Bureaux fail to exercise a right or remedy that it has or which arises
          in relation to this Data Agreement, such
          failure shall not prevent us or them from exercising that right or remedy subsequently in respect of that or
          any other incident. A waiver of any breach
          or provision of this Data Agreement shall only be effective if it is made in writing and signed on behalf of
          the party who is waiving the breach or
          provision. Any waiver of a breach of any term of this Data Agreement shall not be deemed a waiver of any
          subsequent breach and shall not affect
          the enforceability of any other term of this Data Agreement. Variations of this Data Agreement shall not be
          effective unless recorded in writing and
          signed by the parties; bearing in mind that variations in electronic form shall not count as variations
          recorded in writing.</li>
      </ol>
    </li>
  </ol>
</div>