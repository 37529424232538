import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';

import { TermsAndConditionsComponent } from './terms-and-conditions.component';

@NgModule({
  imports: [
    CommonModule,

    FuseSharedModule,

    FlexLayoutModule,

    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule
  ],
  declarations: [TermsAndConditionsComponent],
  exports: [TermsAndConditionsComponent],
  entryComponents: [TermsAndConditionsComponent]
})
export class TermsAndConditionsModule { }
