// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  firebaseConfig: {
    apiKey: "AIzaSyB8hfP-smmNHkAJM8cp1AF2TyackA0fCR8",
    authDomain: "dev-clickncheck.firebaseapp.com",
    databaseURL: "https://dev-clickncheck.firebaseio.com",
    projectId: "dev-clickncheck",
    storageBucket: "dev-clickncheck.appspot.com",
    messagingSenderId: "374039546183",
    appId: "1:374039546183:web:c148c23c51492f3f0d2462",
    measurementId: "G-JS49VJ49HX"
    // apiKey: 'AIzaSyB9PezjIDTj4A0-JPIiJc14dT79MLdgy60',
    // authDomain: 'prod-clickncheck.firebaseapp.com',
    // databaseURL: 'https://prod-clickncheck.firebaseio.com',
    // projectId: 'prod-clickncheck',
    // storageBucket: 'prod-clickncheck.appspot.com',
    // messagingSenderId: '179589014084',
    // appId: '1:179589014084:web:48c406ae30ef2065d79211',
    // measurementId: 'G-PYF462XPRB'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
